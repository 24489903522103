import React from "react";
import CommonContent from "../../components/commonContent";
const content = [
  "98% SiC / 95% SiC / 90% SiC / 88% SiC (FEPA 45-1: 2011 / ISO 9286)",
  "0-25mm (FEPA 45-1: 2011 / ISO 9286)",
  "8-5/5-3/3-1/1-0mm (FEPA 45-1: 2011 / ISO 9286)",
  "F4-F220 (ISO-8486-1:1996 / FEPA 42-1:2006 / FEPA 45-1: 2011 / ISO 9286)",
  "F230-F1200 (ISO-8486-2:1996 / FEPA 42-2:2006 / FEPA 45-1: 2011 / ISO 9286)",
  "P12-P220 (ISO 6344-2:1998 / FEPA 41-1:2006 / FEPA 45-1: 2011 / ISO 9286)",
  "P240-P1200 (ISO 6344-3:1998 / FEPA 45-1: 2011 / ISO 9286)",
  "P240-P5000 (FEPA 43-2:2017 / FEPA 45-1: 2011 / ISO 9286)",
  "#240-#3000 (JIS R6001-1987 / FEPA 45-1: 2011 / ISO 9286)",
  "#240-#8000 (JIS R6001-1987 / FEPA 45-1: 2011 / ISO 9286)",
  "",
  "Acheson 40000 KVA",
  "",
  "Ball Mill",
  "Bamarc",
  "Double Rollers",
  "Jet Mill",
  "Raymond Mill",
];
const C: React.FC = function () {
  const title = (
    <>
      Black Silicon <span className="black">C</span>arbide
    </>
  );
  const desc = [
    "(March 9, 1856 – July 6, 1931)",
    "Dr. Edward Goodrich Acheson",
  ];
  return (
    <CommonContent
      title={title}
      content={content}
      img={process.env.PUBLIC_URL + "/images/C.jpg"}
      desc={desc}
    />
  );
};

export default C;
