import React from "react";
import "./header.css";
import { Link, useLocation } from "react-router-dom";

const Header: React.FC = function () {
  const location = useLocation();
  const { pathname } = location;
  return (
    <header className="header">
      <div className="logo">
        <img
          className="logo"
          src={process.env.PUBLIC_URL + "/images/CHINABRASIVES.png"}
        />
      </div>
      <div className="subtitle">
        For bonded and coated abrasives, shot blasting, refractories,
        metallurgy, flooring, lapping and polishing industries
      </div>
      <div className="first-line">
        <div className="first-line-left"></div>
        <Link
          to="/aboutus"
          className={`menu ${pathname === "/aboutus" ? "active" : ""}`}
        >
          About Us
        </Link>
        <Link
          to="/forstaff"
          className={`menu ${pathname === "/forstaff" ? "active" : ""}`}
        >
          For Staff
        </Link>
        <Link to="/" className={`menu ${pathname === "/" ? "active" : ""}`}>
          Home
        </Link>
      </div>
      <div className="second-line">
        <Link to="/a" className={`menu ${pathname === "/a" ? "active" : ""}`}>
          A
        </Link>
        <Link to="/wa" className={`menu ${pathname === "/wa" ? "active" : ""}`}>
          WA
        </Link>
        <Link to="/c" className={`menu ${pathname === "/c" ? "active" : ""}`}>
          C
        </Link>
        <Link to="/gc" className={`menu ${pathname === "/gc" ? "active" : ""}`}>
          GC
        </Link>
        <Link to="/za" className={`menu ${pathname === "/za" ? "active" : ""}`}>
          ZA
        </Link>
        <Link to="/sg" className={`menu ${pathname === "/sg" ? "active" : ""}`}>
          SG
        </Link>
        <Link to="/pa" className={`menu ${pathname === "/pa" ? "active" : ""}`}>
          PA
        </Link>
        <Link to="/qc" className={`menu ${pathname === "/qc" ? "active" : ""}`}>
          Quality Control
        </Link>
        <Link
          to="/packing"
          className={`menu ${pathname === "/packing" ? "active" : ""}`}
        >
          Packing
        </Link>
      </div>
    </header>
  );
};

export default Header;
