import React from "react";
import "./home.css";
const Home: React.FC = function () {
  return (
    <div className="home-container">
      <div className="home-left">
        <img src={process.env.PUBLIC_URL + "/images/HOME.png"} />
      </div>
      <div className="home-right">
        <div className="info">
          <p>Vairocana Buddha</p>
          <p>Stands for Bright and Intelligence</p>
          <p>Longmen Grottoes, China, 672 AD</p>
          <p>About 20 KM from us</p>
        </div>
        <div className="desc">
          <p>We have been living under the gaze over 1300 years.</p>
          <p>None knows who had created this great masterpiece,</p>
          <p>
            but we know our ancestors cut, ground and polished perfectly at that
            time.
          </p>
          <p>Yes, it is not a story about Buddhism.</p>
          <p>It is a story about Chinese Abrasives...</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
