import React from "react";
import CommonContent from "../../components/commonContent";
const content = [
  "ZA 25%",
  "ZA 40%",
  {
    options: {
      indent: true,
    },
    text: "F4-F220 (ISO-8486-1:1996 / FEPA 42-1:2006)",
  },
  {
    options: {
      indent: true,
    },
    text: "P12-P220 (ISO 6344-2:1998 / FEPA 41-1:2006)",
  },
  "",
  "Tilt (12500 KVA)",
  "",
  "Ball Mill",
  "Bamarc",
  "Double Rolls",
];
const Za: React.FC = function () {
  const title = (
    <>
      <span className="black">Z</span>irconia <span className="black">A</span>
      luminum Oxide
    </>
  );
  const desc = "ZA25 F16";
  return (
    <CommonContent
      title={title}
      content={content}
      img={process.env.PUBLIC_URL + "/images/ZA.png"}
      desc={desc}
    />
  );
};

export default Za;
