import React from "react";
import CommonContent from "../../components/commonContent";
const content = [
  {
    options: {
      indent: true,
    },
    text: "1 MT Big Bag(Plastic Liner / Top & Bottom Opening)",
  },
  "",
  {
    options: {
      indent: true,
    },
    text: "25 kgs Plastic bags x 40 + 1 MT Big Bag (Top Opening)",
  },
  "",
  {
    options: {
      indent: true,
    },
    text: "25 kgs Paper bags x 40 + Pallet (Plastic Shrink Wrap)",
  },
];
const Packing: React.FC = function () {
  const title = <>Packing</>;
  const desc = "";
  return (
    <CommonContent
      title={title}
      content={content}
      img={process.env.PUBLIC_URL + "/images/PACKING.png"}
      desc={desc}
      imgSize="md"
    />
  );
};

export default Packing;
