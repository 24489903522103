import React from "react";
import "./forstaff.css";
const ForStaff: React.FC = function () {
  return (
    <div className="forstaff-container">
      <form>
        <div className="item">
          <label className="label" htmlFor="staff-id">
            Staff Id
          </label>
          <input
            type="text"
            name="staff-key"
            placeholder="Please entry your id"
          />
        </div>
        <div className="item">
          <label className="label" htmlFor="staff-id">
            Key
          </label>
          <input
            type="password"
            name="staff-key"
            placeholder="Please entry your key"
          />
        </div>
        <div className="submit">
          <button>Submit</button>
        </div>
      </form>
    </div>
  );
};

export default ForStaff;
