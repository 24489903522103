import React from "react";
import Title from "../../components/commonTitle";
import "./qc.css";
const Home: React.FC = function () {
  const title = <>Quality Control</>;
  return (
    <div className="qc-container">
      <Title title={title} />
      <div className="qc-content">
        <div className="qc-left">
          <img
            className="qc01"
            src={process.env.PUBLIC_URL + "/images/QC01.png"}
          />
          <div className="text">
            <p>Quality inspected by</p>
            <p>
              China National Quality Supervision and Inspection Center for
              Abrasives
            </p>
            <p style={{ marginTop: "40px" }}>
              Chemical content (1 sample/20 MT)
            </p>
            <p>Grain Distribution (1 sample/ 1 MT)</p>
            <p>Bulk Density (1 sample/ 1 MT)</p>
          </div>
          <img
            className="qc02"
            src={process.env.PUBLIC_URL + "/images/QC02.png"}
          />
        </div>
        <div className="qc-right">
          <img src={process.env.PUBLIC_URL + "/images/QC.png"} />
        </div>
      </div>
    </div>
  );
};

export default Home;
