import React from "react";
import CommonContent from "../../components/commonContent";
const content = [
  "0-25mm",
  "8-5/5-3/3-1/1-0mm",
  "F4-F220",
  "F230-F1200",
  "P12-P220",
  "P240-P1200",
  "P240-P5000",
  "#240-#3000",
  "#240-#8000",
  "High Temperature (1350 °C) Calcined F24-F54",
  "Surface Treated /High Temperature (1350 °C) Calcined F24-F54",
  "Surface Treated /Middle Temperature (1050 °C) Calcined Grit F24-F54",
  "",
  "ISO 6344-2:1998 / ISO 8486-1:1996 / ISO 9284:1992 / ISO 9285:1997 / ISO 9137:1990 / ISO 9138:1993",
  "FEPA 42-1/2-2006 / FEPA 44-1986 R1993",
  "GOST 28818-90 / GOST P 52381-2005 / GOST 27595-88",
  "JIS R6001-1987",
  "",
  "Tilt (7500KVA) / Fixed (5000KVA)",
  "",
  "Double Rollers / Ball Mill / Bamarc",
];
const A: React.FC = function () {
  const title = (
    <>
      Brown <span className="black">A</span>luminum Oxid
    </>
  );
  return (
    <CommonContent
      title={title}
      content={content}
      img={process.env.PUBLIC_URL + "/images/A.png"}
      desc="F46"
    />
  );
};

export default A;
