import React from "react";
import "./pa.css";
import CommonContent from "../../components/commonContent";
const content = [
  "F4-F220 (ISO-8486-1:1996 / FEPA 42-1:2006)",
  "P12-P220 (ISO 6344-2:1998 / FEPA 41-1:2006)",
  "",
  "FEPA 42-1/2-2006:",
  "FEPA 44-1986 R1993:",
  "ISO8486.2-1996",
  "ISO9138:1993",
  "",
  "Fixed(2000KVA)",
  "",
  "Double Rollers",
];
const Pa: React.FC = function () {
  const title = (
    <>
      <span className="black">P</span>ink Fused <span className="black">A</span>
      lumina
    </>
  );
  return (
    <>
      <CommonContent title={title} content={content} desc="" />
      <div className="pa-container">
        <table className="pa-table" cellPadding={0} cellSpacing={0}>
          <tbody>
            <tr>
              <th rowSpan={2}>Grit</th>
              <th colSpan={3}>Low chromium</th>
              <th colSpan={3}>Medium chromium</th>
              <th colSpan={3}>High chromium</th>
            </tr>
            <tr>
              <th className="border-left-1px">AL2O3</th>
              <th>Cr2O3</th>
              <th>Na2O</th>
              <th>AL2O3</th>
              <th>Cr2O3</th>
              <th>Na2O</th>
              <th>AL2O3</th>
              <th>Cr2O3</th>
              <th>Na2O</th>
            </tr>
            <tr>
              <td>F12~F80</td>
              <td rowSpan={2}>≥99.00</td>
              <td rowSpan={3} className="border-bottom-1px">
                0.20~0.45
              </td>
              <td>≤0.50</td>
              <td rowSpan={2}>≥98.20</td>
              <td rowSpan={3} className="border-bottom-1px">
                0.45~1.00
              </td>
              <td>≤0.55</td>
              <td>≥97.50</td>
              <td rowSpan={3} className="border-bottom-1px">
                1.00~2.00
              </td>
              <td>≤0.55</td>
            </tr>
            <tr>
              <td>F90~F150</td>
              <td>≤0.55</td>
              <td>≤0.60</td>
              <td>≥97.00</td>
              <td>≤0.60</td>
            </tr>
            <tr>
              <td>F180~F220</td>
              <td>≥98.50</td>
              <td>≤0.60</td>
              <td>≥97.80</td>
              <td>≤0.70</td>
              <td>≥96.50</td>
              <td>≤0.70</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Pa;
