import React from "react";
import "./about.css";
const arr = [2, 3, 4, 5, 6];
const About: React.FC = function () {
  return (
    <div className="about-us">
      <div className="title">
        <h1>China Abrasives CO., Limited</h1>
      </div>
      <div className="content">
        <div className="content-left">
          <p>Head office:</p>
          <p>220 Zhongyuan Road Zhengzhou</p>
          <p>Henan China</p>
          <p>Tel: +86 136 53868177</p>
          <p>E-mail: info@china-abrasives.ltd</p>
          <p>&nbsp;</p>
          <p>Hongkong office:</p>
          <p>
            Room 1702 Hong Kong Trade Centre Nos. 161-167 Des Voeux Road Central
            Hong Kong, China
          </p>
          <p>Tel: +852-2616 9122</p>
          <p>E-mail: hongkong@china-abrasives.ltd</p>
        </div>
      </div>
      <div className="content-right">
        <p>The main Chinese Abrasives exporter</p>
        <p>Engaged in China Abrasives Industry from 1994</p>
      </div>
      <div className="footer">
        <img src={process.env.PUBLIC_URL + "/images/US01.png"} alt="" />
        {arr.map((item) => {
          return (
            <img
              src={process.env.PUBLIC_URL + `/images/US0${item}.jpg`}
              key={item}
              alt=""
            />
          );
        })}
      </div>
    </div>
  );
};

export default About;
