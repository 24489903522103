import React from "react";
import CommonContent from "../../components/commonContent";
const content = [
  "0-25mm (FEPA 45-1: 2011 / ISO 9286)",
  "8-5/5-3/3-1/1-0mm (FEPA 45-1: 2011 / ISO 9286)",
  "F4-F220 (ISO-8486-1:1996 / FEPA 42-1:2006 / FEPA 45-1 : 2011 / ISO 9286)",
  "F230-F1200 (ISO-8486-2:1996 / FEPA 42-2:2006 / FEPA 45-1 : 2011 / ISO 9286)",
  "P12-P220 (ISO 6344-2:1998 / FEPA 41-1:2006 / FEPA 45-1: 2011 / ISO 9286)",
  "P240-P1200 (ISO 6344-3:1998 / FEPA 45-1: 2011 / ISO 9286)",
  "P240-P5000 (FEPA 43-2:2017 / FEPA 45-1: 2011 / ISO 9286)",
  "#240-#3000 (JIS R6001-1987/ FEPA 45-1: 2011 / ISO 9286)",
  "#240-#8000 (JIS R6001-1987/ FEPA 45-1: 2011 / ISO 9286)",
  "",
  "Acheson 12500 KVA",
  "",
  "Ball Mill",
  "Bamarc",
  "Double Rollers",
  "Jet Mill",
  "Raymond",
];
const Gc: React.FC = function () {
  const title = (
    <>
      <span className="black">G</span>reen Silicon{" "}
      <span className="black">C</span>arbide
    </>
  );
  return (
    <CommonContent
      title={title}
      content={content}
      img={process.env.PUBLIC_URL + "/images/GC.png"}
      desc="3-1mm"
    />
  );
};

export default Gc;
