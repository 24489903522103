import React from 'react'
import './commonTitle.css'
interface CommonTitleProps{
    title: string | JSX.Element
}
const CommonTitle: React.FC<CommonTitleProps> = function(props){
    const {title} = props
    return (
        <h1 className="common-title">{title}</h1>
    )
}

export default CommonTitle