import React from "react";
import CommonContent from "../../components/commonContent";
const content = [
  "F4-F220 (ISO-8486-1:1996 / FEPA 42-1:2006)",
  "P12-P220 (ISO 6344-2:1998 / FEPA 41-1:2006)",
  "",
  "Calcined 800°C / 1300-1400°C",
  "",
  "Double Rollers",
];
const Sg: React.FC = function () {
  const title = (
    <>
      Ceramic Alumina Grain (<span className="black">SG</span>)
    </>
  );
  const desc = "";
  return (
    <CommonContent
      title={title}
      content={content}
      img={process.env.PUBLIC_URL + "/images/SG.png"}
      desc={desc}
    />
  );
};

export default Sg;
