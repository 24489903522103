import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import A from "./containers/a";
import AboutUs from "./containers/aboutUs";
import C from "./containers/c";
import ForStaff from "./containers/forStaff";
import Gc from "./containers/gc";
import Home from "./containers/home";
import Pa from "./containers/pa";
import Packing from "./containers/packing";
import Qc from "./containers/qc";
import Sg from "./containers/sg";
import Wa from "./containers/wa";
import Za from "./containers/za";
import NotFound from "./containers/404";
import Header from "./components/Header";
import "./App.css";

const App: React.FC = function () {
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          <Route path="/a" component={A} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/c" component={C} />
          <Route path="/forstaff" component={ForStaff} />
          <Route path="/gc" component={Gc} />
          <Route exact path="/" component={Home} />
          <Route path="/pa" component={Pa} />
          <Route path="/packing" component={Packing} />
          <Route path="/qc" component={Qc} />
          <Route path="/sg" component={Sg} />
          <Route path="/wa" component={Wa} />
          <Route path="/za" component={Za} />
          <Route path="/*" component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
