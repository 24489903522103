import React from "react";
import Title from "../../components/commonTitle";
import "./commonContent.css";

interface OptionsType {
  indent?: boolean;
}

interface ContentItem {
  text: string;
  options?: OptionsType;
}

interface CommonContentProps {
  title: string | JSX.Element;
  content: (string | ContentItem)[];
  img?: any;
  desc: string | string[];
  imgSize?: "lg" | "auto" | "md";
}
const CommonContent: React.FC<CommonContentProps> = function (props) {
  const { title, content, img, desc, imgSize } = props;
  return (
    <div className="a-container">
      <Title title={title} />
      <article className="a-content">
        <section className="a-left">
          {content.map((item, index) => {
            if (item === "") {
              return <p key={index}>&nbsp;</p>;
            }
            if (typeof item === "object" && item.text) {
              return (
                <p
                  className={
                    item.options && item.options.indent ? "indent" : ""
                  }
                  key={index}
                >
                  {item.text}
                </p>
              );
            }
            return <p key={index}>{item}</p>;
          })}
        </section>
        <section className="a-right">
          {img && <img src={img} className={imgSize || ""} />}
          <div className="desc">
            {Array.isArray(desc)
              ? desc.map((item, index) => <p key={index}>{item}</p>)
              : desc}
          </div>
        </section>
      </article>
    </div>
  );
};

export default CommonContent;
