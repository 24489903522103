import React from "react";
import { useLocation } from "react-router-dom";
import "./404.css";
const NotFound: React.FC = function () {
  const location = useLocation();
  const { pathname } = location;
  return (
    <>
      <div className="notfound">
        <h1>404</h1>
        <p>Page "{pathname}" dose not exist!</p>
      </div>
    </>
  );
};

export default NotFound;
