import React from "react";
import CommonContent from "../../components/commonContent";
const content = [
  "0-25mm",
  "8-5/5-3/3-1/1-0mm",
  "F4-F220 (ISO-8486-1:1996 / FEPA 42-1:2006)",
  "F230-F1200 (ISO-8486-2:1996 / FEPA 42-2:2006)",
  "P12-P220 (ISO 6344-2:1998 / FEPA 41-1:2006)",
  "P240-P1200 (ISO 6344-3:1998)",
  "P240-P5000 (FEPA 43-2:2017)",
  "#240-#3000 (JIS R6001-1987)",
  "#240-#8000 (JIS R6001-1987)",
  "Surface Treated F24-F54",
  "",
  "Tilt(3150KVA)",
  "Fixed(2000KVA)",
  "",
  "Ball Mill",
  "Bamarc ",
  "Double Rollers",
];
const Wa: React.FC = function () {
  const title = (
    <>
      <span className="black">W</span>hite <span className="black">A</span>
      luminum Oxide
    </>
  );
  const desc = "";
  return (
    <CommonContent
      title={title}
      content={content}
      img={process.env.PUBLIC_URL + "/images/WA.png"}
      desc={desc}
    />
  );
};

export default Wa;
